import React from 'react';
import './svgStyle.css';

const TwigBrown = ({ id }) => {
    return (
        <div id={id} className='short svg'>
           <svg 
            height="100%" 
            version="1.1" 
            viewBox="0 0 115.007 133.722"
            width="100%" 
            >
            <g>
            <path d="M109.494 7.76055C109.068 9.30754 107.64 10.1766 106.347 10.9414C104.786 11.8306 103.242 12.7679 101.872 13.9338C102.913 13.8295 103.957 13.6009 105.01 13.6971C105.978 13.78 106.859 14.6344 106.751 15.6493C106.832 16.521 106.045 17.0773 105.339 17.3821C103.853 18.0092 102.218 18.0894 100.656 18.4304C98.3952 18.8047 96.2292 19.6605 94.2516 20.805C93.3612 21.4936 92.4212 22.1274 91.3208 22.4255C92.0522 21.5818 92.9895 20.9695 93.8318 20.2515C94.3506 19.7982 94.9549 19.4626 95.5914 19.2045C97.839 18.2445 100.051 17.1454 102.421 16.513C103.298 16.2469 104.022 17.1281 104.897 16.9957C105.606 16.9248 106.506 16.5184 106.49 15.688C106.415 15.0422 105.762 14.6732 105.194 14.5235C103.376 14.0943 101.532 14.6451 99.7938 15.1706C101.908 13.0848 104.143 11.1273 106.482 9.29685C107.303 8.46519 108.493 8.28067 109.494 7.76055" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M107.848 5.53485C106.563 6.62055 105.374 7.81054 104.177 8.9885C102.245 10.6237 100.472 12.4528 98.3986 13.9143C98.7983 12.1533 99.7249 10.47 99.614 8.62348C99.5939 7.80653 98.8732 6.99226 98.0108 7.14736C97.1912 7.23026 96.6457 8.00576 96.5654 8.77858C96.4625 9.80144 96.6055 10.8337 96.4732 11.8538C96.2633 13.8969 95.2351 15.7193 94.2376 17.4709C93.3591 19.078 91.9004 20.244 90.4965 21.3738C91.058 20.2052 92.0796 19.3428 92.6438 18.1755C93.0543 17.4294 93.2896 16.6125 93.5316 15.8022L93.5624 15.7287C94.5505 14.0239 94.3566 11.9782 94.9302 10.1411C95.4022 8.59674 95.9544 6.8639 97.353 5.90923C98.3264 5.22332 99.7918 5.60171 100.305 6.6727C101.081 7.99239 100.615 9.54873 100.356 10.9433C102.002 9.49525 103.568 7.95361 105.294 6.6005C106.023 5.99614 106.946 5.74878 107.848 5.53485" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M93.5316 15.8025C93.2896 16.6127 93.0542 17.4297 92.6438 18.1758C92.0795 19.3417 91.058 20.2041 90.4964 21.374C90.2745 21.5853 90.0512 21.7939 89.8239 22.0038C89.7076 20.2643 90.5299 18.6411 91.4431 17.2211C91.8763 16.4403 92.7801 16.1662 93.5316 15.8025" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M110.139 4.81874C110.78 4.99657 111.117 5.80817 110.772 6.38044C110.435 6.91393 109.928 7.3097 109.495 7.76029C108.493 8.28041 107.303 8.46627 106.482 9.29792C104.144 11.127 101.908 13.0845 99.7943 15.1703C101.532 14.6449 103.375 14.094 105.195 14.5232C105.763 14.6743 106.415 15.042 106.49 15.6878C106.505 16.5181 105.605 16.9246 104.898 16.9954C104.022 17.1291 103.299 16.2467 102.422 16.5141C100.05 17.1452 97.8395 18.2456 95.5905 19.2043C94.9554 19.4637 94.3497 19.7979 93.8323 20.2512C92.9899 20.9705 92.0526 21.5829 91.3213 22.4253C91.1327 22.6218 90.9442 22.817 90.7557 23.0176C90.2904 22.8331 89.9321 22.5068 89.8238 22.0041C90.0524 21.7942 90.2757 21.5856 90.4976 21.3743C91.9002 20.2445 93.359 19.0786 94.2374 17.4714C95.2362 15.7199 96.2631 13.8974 96.473 11.8544C96.6054 10.8342 96.4623 9.802 96.5653 8.77914C96.6455 8.00498 97.191 7.23081 98.0106 7.14791C98.873 6.99148 99.5937 7.80575 99.6138 8.6227C99.7248 10.4705 98.7995 12.1526 98.3984 13.9135C100.474 12.4534 102.245 10.6243 104.177 8.98906C105.375 7.80976 106.563 6.62111 107.847 5.53541C108.545 5.15836 109.3 4.51656 110.139 4.81874" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M108.703 12.6016C109.167 13.3129 108.543 14.3758 107.684 14.202C106.906 14.1472 106.487 13.1658 106.938 12.5467C107.312 11.8862 108.351 11.9504 108.703 12.6016" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M104.356 3.79692C104.602 4.58178 103.582 5.30113 102.925 4.80507C102.321 4.46412 102.375 3.52416 102.966 3.20326C103.478 2.83824 104.234 3.20594 104.356 3.79692" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M108.125 42.7875C106.604 43.3009 105.085 42.6057 103.726 41.9625C102.117 41.1696 100.474 40.4155 98.7396 39.9329C99.4095 40.7378 100.186 41.4745 100.694 42.3984C101.168 43.2488 100.955 44.4562 100.054 44.9362C99.3774 45.4911 98.4749 45.1514 97.8264 44.7369C96.476 43.8585 95.4919 42.5482 94.3353 41.4464C92.7589 39.7831 90.8349 38.4688 88.7785 37.4727C87.7088 37.1197 86.6579 36.6972 85.7955 35.9537C86.9026 36.0861 87.9348 36.5193 89.0018 36.8135C89.6676 36.99 90.2853 37.3028 90.8549 37.6852C92.91 39.0089 95.06 40.2243 96.9119 41.8342C97.6245 42.4118 97.2996 43.5055 97.9 44.1553C98.3559 44.7022 99.1956 45.2196 99.8761 44.7396C100.368 44.3184 100.307 43.5697 100.115 43.0161C99.451 41.2686 97.9601 40.0505 96.5535 38.906C99.463 39.4863 102.339 40.2417 105.164 41.1536C106.314 41.3675 107.134 42.2487 108.125 42.7875" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M109.045 40.1759C107.426 39.72 105.776 39.4031 104.129 39.0715C101.691 38.3883 99.1828 37.9457 96.8108 37.0445C98.4928 36.3893 100.406 36.2142 101.874 35.087C102.54 34.6137 102.81 33.5601 102.198 32.933C101.668 32.3006 100.722 32.2832 100.037 32.6496C99.1319 33.1376 98.3578 33.8342 97.4379 34.2968C95.6288 35.2675 93.543 35.4387 91.532 35.5924C89.7096 35.7663 87.9273 35.2114 86.2038 34.6806C87.4874 34.492 88.7737 34.8544 90.0559 34.6672C90.9036 34.5883 91.7126 34.3262 92.5201 34.0735L92.5977 34.0575C94.5632 33.9198 96.1489 32.6135 97.9914 32.0586C99.5371 31.5852 101.281 31.0705 102.854 31.6949C103.968 32.1147 104.476 33.5414 103.877 34.5682C103.218 35.9494 101.667 36.4348 100.369 37.0031C102.491 37.5539 104.645 37.9858 106.732 38.6583C107.641 38.9231 108.363 39.5502 109.045 40.1759" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M106.262 35.6065C106.281 35.1973 105.709 35.2816 105.439 35.2241C105.4 35.5035 105.161 35.9394 105.543 36.0905C105.823 36.4261 106.407 36.0009 106.262 35.6065M108.529 36.3111C108.95 35.8792 108.83 35.0449 108.24 34.8256C107.642 34.5154 106.836 34.9981 106.891 35.6907C106.849 36.5117 108.017 36.9542 108.529 36.3111M103.673 44.8443C104.015 44.1891 103.485 43.2919 102.727 43.3521C101.962 43.3254 101.386 44.2225 101.777 44.8978C102.112 45.7054 103.342 45.6265 103.673 44.8443M113.435 45.3713C112.214 47.1964 108.313 47.2477 104.923 46.1335C94.4596 49.3503 93.4921 47.4101 90.8527 41.3438C91.6389 41.4241 92.5388 41.8626 93.2621 41.3719C92.5241 40.9721 91.6376 41.2115 90.8701 40.8972C89.9943 40.555 89.3178 39.8704 88.5383 39.3663C87.1664 38.413 85.5553 37.4944 84.9884 35.8164C85.2598 35.8592 85.5272 35.906 85.7946 35.9528C86.6584 36.6975 87.7093 37.1187 88.7789 37.473C90.8354 38.4678 92.7581 39.7835 94.3345 41.4454C95.491 42.5485 96.4765 43.8575 97.8256 44.7373C98.4754 45.1505 99.3779 45.4914 100.053 44.9365C100.954 44.4565 101.167 43.2478 100.695 42.3988C100.185 41.4749 99.41 40.7368 98.7401 39.9332C100.474 40.4159 102.116 41.1687 103.726 41.9616C105.085 42.606 106.605 43.3 108.125 42.7879C108.741 42.8935 109.352 43.0914 109.982 43.0713C110.651 43.0379 111.135 42.3025 110.922 41.6728C110.702 40.809 109.748 40.5429 109.045 40.1752C108.363 39.5495 107.641 38.9224 106.732 38.6577C104.645 37.9851 102.491 37.5546 100.369 37.0024C101.667 36.4341 103.218 35.9488 103.877 34.5676C104.476 33.542 103.968 32.1154 102.854 31.6955C101.281 31.0711 99.537 31.5846 97.9914 32.0592C96.1502 32.6141 94.5631 33.9191 92.5976 34.0568C93.1485 33.538 92.1577 33.2586 91.8395 32.9952C93.2862 31.1741 95.4255 29.9934 97.6905 29.5469C101.965 28.6818 104.005 25.5886 106.661 32.7739C114.983 34.8162 113.471 36.4377 112.94 39.9221C113.72 41.2586 113.798 44.1112 113.464 45.3092" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M92.5197 34.073C91.7121 34.3271 90.9032 34.5891 90.0555 34.6667C88.7733 34.8539 87.487 34.4915 86.2034 34.6814C85.9052 34.6159 85.6071 34.5477 85.3049 34.4768C86.6807 33.4058 88.4858 33.1772 90.1745 33.1371C91.0623 33.0582 91.7964 33.6532 92.5197 34.073" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M110.923 41.6724C111.134 42.3021 110.651 43.0375 109.983 43.0723C109.352 43.0923 108.741 42.8944 108.124 42.7875C107.133 42.2486 106.314 41.3675 105.164 41.1536C102.339 40.2417 99.4639 39.4863 96.5531 38.906C97.9611 40.0505 99.4506 41.2686 100.115 43.0161C100.308 43.5697 100.368 44.3184 99.8758 44.7396C99.1952 45.2196 98.3568 44.7022 97.8996 44.1553C97.3006 43.5055 97.6255 42.4118 96.9115 41.8342C95.0596 40.2243 92.9096 39.0089 90.8546 37.6852C90.285 37.3028 89.6686 36.9899 89.0014 36.8135C87.9357 36.5193 86.9022 36.0861 85.7951 35.9537C85.5277 35.9069 85.2603 35.8601 84.9888 35.8173C84.8792 35.328 84.9487 34.848 85.3057 34.4763C85.6066 34.5485 85.9047 34.6167 86.2042 34.6808C87.9277 35.2103 89.71 35.7665 91.5324 35.5927C93.5434 35.4376 95.6292 35.2678 97.4383 34.2971C98.3582 33.8345 99.1323 33.1379 100.036 32.6498C100.722 32.2835 101.669 32.3008 102.198 32.9319C102.811 33.5604 102.539 34.614 101.873 35.0873C100.407 36.2131 98.4932 36.3896 96.8099 37.0448C99.1832 37.9446 101.691 38.3872 104.129 39.0718C105.775 39.4034 107.426 39.7202 109.045 40.1762C109.749 40.5425 110.702 40.8086 110.923 41.6724" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M103.672 44.8439C103.343 45.6261 102.112 45.705 101.776 44.8987C101.386 44.2222 101.963 43.325 102.728 43.3517C103.485 43.2916 104.016 44.1887 103.672 44.8439" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M108.529 36.311C108.016 36.9541 106.85 36.5116 106.89 35.6906C106.835 34.998 107.643 34.5153 108.24 34.8255C108.83 35.0448 108.949 35.8791 108.529 36.311" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M87.6219 62.9401C86.1191 63.5016 84.5788 62.8558 83.1989 62.2541C81.5663 61.5134 79.9017 60.8114 78.1528 60.3836C78.8481 61.1658 79.6463 61.8784 80.1838 62.7876C80.6852 63.6206 80.5101 64.8347 79.6236 65.3428C78.9644 65.9191 78.0525 65.6062 77.392 65.2131C76.0122 64.3787 74.9893 63.1005 73.7966 62.0335C72.1694 60.4224 70.2066 59.1695 68.1181 58.2376C67.0391 57.9194 65.9761 57.5316 65.087 56.8136C66.2007 56.9112 67.2463 57.3137 68.3213 57.5731C68.9925 57.7282 69.6183 58.021 70.2012 58.3847C72.2964 59.6442 74.4839 60.7914 76.3852 62.3411C77.1166 62.8959 76.8251 63.999 77.4468 64.6301C77.9188 65.1623 78.7745 65.653 79.4391 65.1529C79.9177 64.7144 79.8335 63.9696 79.6249 63.4214C78.9042 61.6953 77.3773 60.5253 75.9346 59.4262C78.8614 59.9143 81.7589 60.5788 84.6122 61.4011C85.7661 61.5789 86.6138 62.4333 87.6219 62.9401" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M88.4601 60.3012C86.8276 59.8974 85.1669 59.6327 83.5116 59.3532C81.0541 58.7462 78.5324 58.3825 76.1323 57.5576C77.793 56.8502 79.6996 56.6136 81.133 55.441C81.7828 54.9476 82.0195 53.886 81.387 53.2776C80.8402 52.6625 79.8922 52.6746 79.2183 53.0623C78.3305 53.5798 77.5764 54.3005 76.6739 54.7912C74.8956 55.818 72.8164 56.0547 70.8121 56.2726C68.9937 56.5039 67.1967 56.0052 65.4572 55.5292C66.7327 55.2992 68.031 55.6215 69.3066 55.3942C70.1516 55.2885 70.9512 55.0024 71.7508 54.723L71.8283 54.7042C73.7871 54.505 75.3328 53.1492 77.1565 52.5369C78.6835 52.0141 80.4123 51.4458 82.0061 52.0208C83.1319 52.4045 83.6841 53.8138 83.1185 54.8593C82.5021 56.2606 80.9685 56.7941 79.6876 57.4038C81.8256 57.8878 83.9916 58.2515 86.0989 58.8572C87.0174 59.0938 87.7595 59.6969 88.4601 60.3012" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M71.7506 54.7231C70.951 55.0012 70.1515 55.2887 69.3064 55.3943C68.0309 55.6216 66.7326 55.2994 65.457 55.5293C65.1562 55.4745 64.8567 55.4157 64.5518 55.3542C65.8929 54.2404 67.6899 53.9543 69.3773 53.8607C70.2624 53.7551 71.0139 54.326 71.7506 54.7231" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M90.3835 61.7388C90.6148 62.3606 90.1548 63.1107 89.489 63.1668C88.8579 63.2069 88.2415 63.0278 87.6224 62.9395C86.6143 62.4341 85.7666 61.5797 84.6127 61.4006C81.7594 60.5796 78.8619 59.9151 75.9338 59.4257C77.3765 60.5248 78.9047 61.696 79.6241 63.4222C79.8326 63.9691 79.9182 64.7151 79.4382 65.1537C78.7737 65.6538 77.9193 65.1631 77.446 64.6296C76.8242 63.9985 77.1157 62.8954 76.3843 62.3405C74.483 60.7908 72.2969 59.6436 70.2004 58.3855C69.6188 58.0218 68.993 57.7276 68.3218 57.5725C67.2455 57.3131 66.1999 56.912 65.0874 56.8144C64.8187 56.7756 64.5499 56.7382 64.2785 56.7034C64.1542 56.2181 64.2077 55.7354 64.5526 55.3543C64.8561 55.4158 65.157 55.4747 65.4565 55.5295C67.196 56.0041 68.9944 56.5029 70.8114 56.2729C72.8157 56.0549 74.8962 55.8183 76.6732 54.7914C77.577 54.3007 78.3298 53.5787 79.2176 53.0626C79.8915 52.6748 80.8395 52.6628 81.3863 53.2779C82.0188 53.8849 81.7821 54.9465 81.1323 55.4412C79.7003 56.6138 77.7936 56.8505 76.133 57.5578C78.533 58.3828 81.0534 58.7465 83.5109 59.3535C85.1676 59.6316 86.8282 59.8977 88.4608 60.3015C89.1748 60.6464 90.1361 60.8818 90.3835 61.7388" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M83.2359 65.1363C82.931 65.9292 81.7036 66.0469 81.3426 65.2513C80.9308 64.5881 81.4817 63.6722 82.2465 63.6749C83.0006 63.592 83.5595 64.4705 83.2359 65.1363" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M87.8216 56.4545C87.3296 57.1136 86.1503 56.7085 86.1663 55.8862C86.0888 55.1963 86.8803 54.6882 87.4874 54.9783C88.0823 55.1802 88.2308 56.0092 87.8216 56.4545" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M67.6298 80.2318C66.1443 80.8362 64.5853 80.2359 63.1894 79.677C61.5327 78.983 59.8494 78.3319 58.0885 77.9562C58.8065 78.7183 59.6261 79.4069 60.1903 80.2987C60.7145 81.117 60.5754 82.3351 59.705 82.8699C59.0645 83.4649 58.1419 83.1801 57.4694 82.807C56.0681 82.0128 55.0065 80.7653 53.7844 79.7358C52.1091 78.1728 50.1088 76.9774 47.9949 76.1097C46.9065 75.8222 45.8315 75.4665 44.9237 74.7753C46.0388 74.8394 47.0951 75.2098 48.1781 75.4385C48.8533 75.5735 49.4884 75.8489 50.0794 76.1926C52.212 77.3906 54.4329 78.4723 56.3797 79.9658C57.1271 80.4979 56.8677 81.609 57.5082 82.2214C57.9962 82.7402 58.8653 83.2055 59.5164 82.6854C59.9804 82.2334 59.8748 81.49 59.6488 80.9485C58.8787 79.2464 57.317 78.1206 55.8422 77.0643C58.7837 77.4668 61.6985 78.0444 64.5746 78.7811C65.7352 78.9255 66.6069 79.7545 67.6298 80.2318" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M68.3884 77.5681C66.7452 77.2125 65.0765 76.9972 63.4132 76.7672C60.9396 76.2337 58.4085 75.9449 55.9858 75.1921C57.625 74.434 59.5236 74.1412 60.9195 72.9271C61.556 72.415 61.7606 71.3467 61.1121 70.7571C60.5452 70.1594 59.5985 70.1982 58.9367 70.6073C58.0636 71.1488 57.3322 71.8923 56.443 72.411C54.6982 73.4901 52.6257 73.7869 50.6295 74.065C48.8191 74.3498 47.006 73.9045 45.2544 73.4794C46.5233 73.2119 47.8296 73.4954 49.0985 73.232C49.9395 73.101 50.7311 72.7908 51.5213 72.4873L51.5975 72.4672C53.551 72.2091 55.0538 70.8092 56.8602 70.142C58.3724 69.5751 60.0825 68.9561 61.691 69.4815C62.8289 69.8332 63.4225 71.2264 62.8877 72.2867C62.3141 73.7053 60.7965 74.2856 59.5343 74.9314C61.6857 75.3526 63.8611 75.6508 65.9857 76.1949C66.911 76.4049 67.6704 76.9852 68.3884 77.5681" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M51.5211 72.4875C50.7308 72.791 49.9393 73.1012 49.0983 73.2323C47.8294 73.4957 46.5244 73.2122 45.2542 73.4796C44.952 73.4342 44.6499 73.3847 44.345 73.3312C45.6513 72.1786 47.4403 71.8404 49.1223 71.696C50.0048 71.5649 50.7736 72.1131 51.5211 72.4875" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M70.3535 78.9484C70.6036 79.5634 70.1663 80.3256 69.5018 80.4031C68.8734 80.4606 68.2517 80.3002 67.6299 80.232C66.6071 79.7546 65.7353 78.9257 64.5747 78.7813C61.6987 78.0445 58.7839 77.4669 55.841 77.0645C57.3171 78.1207 58.8788 79.2466 59.649 80.9487C59.8749 81.4902 59.9806 82.2336 59.5166 82.6855C58.8654 83.2056 57.9963 82.7403 57.5083 82.2215C56.8679 81.6092 57.1259 80.4981 56.3798 79.9659C54.4331 78.4724 52.2122 77.3907 50.0796 76.1927C49.4886 75.8491 48.8535 75.5736 48.1782 75.4386C47.0939 75.2099 46.0376 74.8396 44.9225 74.7754C44.6537 74.7446 44.3836 74.7152 44.1095 74.6872C43.9718 74.2071 44.0119 73.7231 44.3449 73.3314C44.6497 73.3849 44.9519 73.4343 45.2554 73.4798C47.0056 73.905 48.82 74.3502 50.6291 74.0654C52.6253 73.7873 54.6978 73.4905 56.444 72.4101C57.3332 71.8927 58.0645 71.1493 58.9363 70.6078C59.5982 70.1986 60.5448 70.1598 61.1117 70.7575C61.7602 71.3458 61.5556 72.4141 60.9192 72.9276C59.5233 74.1416 57.6246 74.4344 55.9854 75.1912C58.4082 75.9453 60.9392 76.2341 63.4141 76.7676C65.0775 76.9976 66.7448 77.2129 68.388 77.5685C69.1127 77.8921 70.0821 78.098 70.3535 78.9484" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M63.3103 82.5574C63.0296 83.3583 61.8061 83.5121 61.4211 82.7272C60.9905 82.0761 61.512 81.1455 62.2768 81.1254C63.0296 81.0198 63.6139 81.8822 63.3103 82.5574" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M67.6376 73.7424C67.1643 74.4163 65.973 74.0473 65.9649 73.2236C65.8673 72.5364 66.6428 72.0042 67.2592 72.277C67.8596 72.4615 68.0307 73.2851 67.6376 73.7424" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M70.4422 6.00953C71.7125 6.98826 71.9785 8.63821 72.1965 10.1264C72.4305 11.906 72.7153 13.6897 73.2768 15.3984C73.5737 14.3956 73.7568 13.342 74.2435 12.4047C74.6874 11.5396 75.8106 11.0476 76.7091 11.5303C77.5461 11.7843 77.7654 12.7243 77.7787 13.4931C77.7988 15.1043 77.256 16.6499 76.9805 18.225C76.4724 20.4579 76.4457 22.7884 76.7599 25.0521C77.0581 26.1364 77.2921 27.2449 77.1517 28.3774C76.6476 27.3812 76.4337 26.2822 76.0874 25.2312C75.8641 24.5787 75.7825 23.8928 75.7825 23.2069C75.7437 20.7628 75.5619 18.2999 75.8734 15.8651C75.9577 14.9518 77.0474 14.6162 77.256 13.7552C77.4578 13.0719 77.4217 12.0865 76.6462 11.787C76.0205 11.6118 75.4322 12.0771 75.0779 12.5451C73.9935 14.068 73.8077 15.9827 73.6365 17.7904C72.504 15.0454 71.536 12.2349 70.727 9.37894C70.2671 8.30394 70.5439 7.13267 70.4422 6.00953" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M67.759 6.69304C68.2792 8.29217 68.9317 9.84183 69.5694 11.3955C70.353 13.8022 71.3772 16.1354 71.9454 18.609C70.4666 17.5714 69.2579 16.0793 67.5077 15.4829C66.7429 15.1914 65.7173 15.5511 65.5355 16.4095C65.3015 17.1997 65.8136 17.998 66.4995 18.3643C67.4061 18.8457 68.4169 19.1037 69.3114 19.6118C71.1231 20.5772 72.4227 22.2177 73.6676 23.8049C74.8228 25.2248 75.3509 27.0152 75.8657 28.7426C74.9966 27.78 74.5835 26.5097 73.717 25.5457C73.1822 24.8839 72.515 24.3571 71.8545 23.8262L71.7983 23.7701C70.5936 22.2124 68.6268 21.6174 67.1427 20.3926C65.8912 19.3711 64.4953 18.2052 64.1409 16.5499C63.8722 15.3907 64.7774 14.1766 65.962 14.1044C67.4783 13.8865 68.7418 14.9067 69.9358 15.6715C69.2165 13.6017 68.3808 11.5693 67.7818 9.45943C67.497 8.55691 67.6173 7.60759 67.759 6.69304" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M65.5027 11.5439C65.1524 11.7552 65.5402 12.1844 65.6418 12.4411C65.8958 12.3181 66.3906 12.2766 66.3063 11.8742C66.428 11.4557 65.7501 11.2043 65.5027 11.5439M64.8302 9.26822C64.2379 9.15724 63.6108 9.72015 63.7539 10.3312C63.8287 11.0011 64.6791 11.4049 65.2233 10.9743C65.9293 10.5531 65.6498 9.33775 64.8302 9.26822M74.6242 8.57161C73.8888 8.64916 73.4369 9.58912 73.9076 10.1854C74.31 10.8366 75.377 10.8192 75.7233 10.1186C76.2073 9.39123 75.4572 8.41116 74.6242 8.57161M70.1918 0.854043C72.386 0.856717 74.8135 3.68379 75.3801 6.12112C79.1432 4.36636 82.41 15.614 78.8293 21.1788C78.4576 20.4795 78.3239 19.4861 77.5123 19.1572C77.5925 19.9942 78.2838 20.5972 78.4469 21.4101C78.6488 22.3287 78.4549 23.2713 78.467 24.2006C78.4362 25.8706 78.5672 27.7211 77.4856 29.1237C77.3719 28.8737 77.2623 28.625 77.1513 28.3763C77.2917 27.2451 77.059 26.1367 76.7595 25.0523C76.4467 22.7887 76.4721 20.4582 76.9801 18.2252C77.2556 16.6502 77.7984 15.1045 77.7797 13.4934C77.765 12.7245 77.5471 11.7846 76.7101 11.5305C75.8102 11.0465 74.6871 11.5399 74.2432 12.405C73.7565 13.3423 73.5733 14.3959 73.2765 15.3987C72.7162 13.6886 72.4314 11.9063 72.1975 10.1253C71.9795 8.63846 71.7134 6.98852 70.4419 6.00978C70.1892 5.43752 70.014 4.81979 69.649 4.30502C69.2479 3.76885 68.3694 3.77554 67.963 4.30101C67.3653 4.96419 67.6741 5.90415 67.7584 6.69302C67.6167 7.60758 67.4963 8.5569 67.7811 9.45942C68.3801 11.5693 69.2158 13.6017 69.9351 15.6714C68.7425 14.9066 67.4789 13.8865 65.9627 14.1044C64.7767 14.1766 63.8715 15.3907 64.1403 16.5499C64.4946 18.2052 65.8905 19.3711 67.1433 20.3926C68.6275 21.6174 70.5943 22.2124 71.799 23.7701C71.0609 23.6003 71.3792 24.579 71.335 24.9908C69.0179 24.7996 66.8492 23.6738 65.2206 22.0372C62.1293 18.962 61.8418 13.666 62.8058 10.5145C62.1898 6.35017 62.095 1.59706 65.2893 0.104887C66.4111-0.494113 69.0085 1.24179 70.1918 0.854043" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M71.8551 23.8269C72.5143 24.3564 73.1815 24.8845 73.7177 25.5464C74.5841 26.5091 74.9972 27.7806 75.8663 28.742C75.9787 29.0268 76.087 29.3129 76.1953 29.6044C74.5413 29.0535 73.3486 27.679 72.3793 26.2978C71.8204 25.6012 71.9073 24.6612 71.8551 23.8269" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M67.9627 4.30102C68.3691 3.77555 69.2476 3.76886 69.6487 4.30503C70.0137 4.8198 70.1889 5.43753 70.4416 6.00979C70.5445 7.13293 70.2678 8.3042 70.7264 9.37921C71.5366 12.2352 72.5033 15.0457 73.6358 17.7894C73.807 15.983 73.9942 14.0683 75.0772 12.5454C75.4329 12.0774 76.0198 11.6121 76.6456 11.7873C77.4211 12.0868 77.4585 13.0722 77.2553 13.7554C77.048 14.6152 75.957 14.9521 75.8728 15.8653C75.5626 18.3001 75.7431 20.763 75.7832 23.2072C75.7832 23.8918 75.8634 24.579 76.0867 25.2315C76.4343 26.2811 76.6469 27.3815 77.151 28.3763C77.262 28.625 77.3716 28.8737 77.4853 29.1237C77.139 29.4847 76.7004 29.6946 76.195 29.6037C76.0867 29.3136 75.9784 29.0274 75.8661 28.7426C75.35 27.0152 74.8232 25.2248 73.6679 23.8048C72.4231 22.2177 71.1222 20.5772 69.3118 19.6118C68.4173 19.1037 67.4064 18.8457 66.4986 18.3643C65.814 17.998 65.3019 17.1997 65.5359 16.4095C65.7164 15.5525 66.7433 15.1928 67.5067 15.4829C69.2583 16.0793 70.467 17.5714 71.9458 18.609C71.3775 16.1354 70.3533 13.8022 69.5698 11.3955C68.932 9.84317 68.2782 8.29217 67.7594 6.69303C67.6739 5.90416 67.365 4.9642 67.9627 4.30102" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M74.6247 8.57203C75.4577 8.41158 76.2078 9.39165 75.7224 10.119C75.3761 10.8183 74.3105 10.8357 73.908 10.1859C73.436 9.5882 73.8893 8.64958 74.6247 8.57203" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M64.8304 9.26794C65.65 9.33747 65.9294 10.5529 65.2235 10.974C64.6793 11.4046 63.8289 11.0008 63.754 10.3309C63.611 9.71987 64.2381 9.15696 64.8304 9.26794" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M50.2349 22.2405C51.5853 23.1055 51.9918 24.7274 52.3381 26.1902C52.7245 27.9431 53.1618 29.696 53.8691 31.3499C54.0776 30.3244 54.1686 29.2601 54.5737 28.2854C54.9414 27.3842 56.0177 26.7959 56.955 27.1997C57.8107 27.3815 58.1102 28.2987 58.1905 29.0635C58.3482 30.6667 57.9418 32.2538 57.8027 33.8462C57.4898 36.1152 57.665 38.4377 58.1717 40.6666C58.5635 41.7216 58.8911 42.8059 58.8483 43.9451C58.2613 42.9971 57.9538 41.9195 57.5166 40.9033C57.2385 40.2735 57.0981 39.5956 57.0406 38.9124C56.7892 36.4816 56.3948 34.0428 56.4964 31.5919C56.5017 30.6734 57.5594 30.2442 57.6904 29.3697C57.8335 28.6718 57.7131 27.693 56.9136 27.4604C56.2758 27.34 55.7289 27.8548 55.4174 28.3522C54.4681 29.962 54.4467 31.8861 54.4346 33.7018C53.0682 31.0651 51.8621 28.3482 50.8085 25.5738C50.259 24.5416 50.4328 23.3516 50.2349 22.2405" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M47.6207 23.1532C48.2759 24.7015 49.0594 26.1897 49.8296 27.6818C50.819 30.0123 52.0411 32.2479 52.8206 34.6627C51.2576 33.7575 49.9245 32.3736 48.1275 31.931C47.3413 31.7077 46.3505 32.1543 46.2436 33.0248C46.0804 33.8323 46.6567 34.5824 47.3721 34.8886C48.3174 35.2911 49.3469 35.4609 50.2815 35.8888C52.1695 36.695 53.6068 38.2179 54.9827 39.6914C56.2569 41.0057 56.9374 42.7439 57.6006 44.4206C56.6513 43.5381 56.1299 42.3067 55.1832 41.4216C54.5922 40.8092 53.8822 40.3412 53.1803 39.8692L53.1174 39.8171C51.783 38.3704 49.7721 37.9465 48.189 36.8555C46.8533 35.9449 45.3624 34.9033 44.865 33.2855C44.4987 32.153 45.2956 30.8654 46.4709 30.6916C47.9617 30.3426 49.3095 31.2505 50.5636 31.9096C49.6678 29.9094 48.6597 27.9573 47.8801 25.9089C47.5205 25.0318 47.5579 24.0758 47.6207 23.1532" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M45.7914 28.1808C45.4598 28.4215 45.8837 28.8159 46.008 29.0633C46.25 28.9175 46.7394 28.8333 46.6191 28.4402C46.7073 28.0123 46.008 27.8211 45.7914 28.1808M44.9263 25.9706C44.3247 25.9118 43.7497 26.5269 43.9449 27.1232C44.0773 27.7837 44.9584 28.1126 45.4638 27.6366C46.1324 27.1566 45.7486 25.9693 44.9263 25.9706M54.6228 24.433C53.8967 24.5734 53.5264 25.5481 54.0478 26.1017C54.5051 26.7154 55.5667 26.6057 55.8489 25.8784C56.27 25.1122 55.4397 24.2004 54.6228 24.433M49.5406 17.1259C51.7267 16.9387 54.1382 19.0714 55.2156 21.9413C61.2119 23.0146 62.9861 30.7774 59.9002 36.6284C59.4683 35.9639 59.249 34.9878 58.4134 34.7284C58.5644 35.5561 59.3052 36.0963 59.5378 36.8932C59.8186 37.7903 59.7063 38.7463 59.7999 39.6702C59.9135 41.3376 60.201 43.1694 59.2463 44.6615C59.1113 44.4209 58.9803 44.1842 58.8492 43.9462C58.8907 42.8057 58.5644 41.7213 58.1713 40.6664C57.6646 38.4375 57.4894 36.115 57.8023 33.846C57.9414 32.2535 58.3492 30.6664 58.1901 29.0633C58.1098 28.2985 57.8103 27.3812 56.9559 27.2007C56.0173 26.7956 54.941 27.3839 54.5733 28.2851C54.1695 29.2598 54.0772 30.3255 53.8687 31.351C53.1627 29.6957 52.7255 27.9428 52.3377 26.1899C51.9927 24.7272 51.5849 23.1066 50.2345 22.2402C49.9337 21.692 49.7064 21.0917 49.2959 20.6117C48.852 20.1116 47.9762 20.1932 47.6165 20.7521C47.079 21.4634 47.4681 22.3753 47.6205 23.1534C47.5577 24.076 47.5202 25.032 47.8799 25.9078C48.6608 27.9575 49.6676 29.9083 50.5634 31.9099C49.3092 31.2507 47.9615 30.3429 46.4706 30.6918C45.2954 30.8656 44.4985 32.1532 44.8662 33.2844C45.3622 34.9036 46.8531 35.9452 48.1888 36.8544C49.7732 37.9468 51.7828 38.3706 53.1172 39.8173C52.3685 39.713 52.7709 40.6597 52.7629 41.0742C50.4377 41.0822 48.1794 40.1489 46.4158 38.6581C43.0705 35.8623 42.1483 30.557 43.2851 28.6483C42.8102 25.1313 40.4871 17.4415 43.5396 15.6792C44.6066 14.9853 48.3947 17.614 49.5406 17.1259" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M53.1806 39.8691C53.8812 40.3411 54.5925 40.809 55.1835 41.4214C56.1301 42.3065 56.6516 43.538 57.6009 44.4205C57.7359 44.6946 57.8697 44.97 58.002 45.2508C56.3066 44.8456 55.0003 43.5781 53.9146 42.2852C53.2982 41.6407 53.3036 40.6967 53.1806 39.8691" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M47.6168 20.7526C47.9764 20.1937 48.8522 20.1121 49.2961 20.6122C49.7053 21.0922 49.9326 21.6925 50.2347 22.2407C50.4326 23.3505 50.2588 24.5405 50.8083 25.5727C51.8619 28.3485 53.068 31.0641 54.4345 33.7021C54.4465 31.8864 54.4679 29.9623 55.4172 28.3525C55.7287 27.8551 56.2756 27.3403 56.9134 27.4607C57.713 27.692 57.8333 28.672 57.6902 29.37C57.5592 30.2444 56.5016 30.6736 56.4962 31.5909C56.3946 34.0431 56.789 36.4819 57.0404 38.9127C57.0979 39.5959 57.2383 40.2738 57.5164 40.9036C57.9536 41.9197 58.2612 42.9974 58.8481 43.9454C58.9805 44.1834 59.1115 44.4214 59.2466 44.6607C58.9324 45.0511 58.5139 45.2972 58.0031 45.2504C57.8694 44.9696 57.7357 44.6941 57.6006 44.4214C56.9375 42.7447 56.2569 41.0052 54.9827 39.6908C53.6055 38.2174 52.1695 36.6958 50.2815 35.8895C49.3469 35.4603 48.3174 35.2905 47.3721 34.8894C46.6567 34.5832 46.0805 33.8318 46.2436 33.0255C46.3505 32.1551 47.3413 31.7085 48.1275 31.9318C49.9245 32.3744 51.2576 33.7583 52.8206 34.6621C52.0411 32.2474 50.819 30.0118 49.8296 27.6826C49.0594 26.1891 48.2759 24.7023 47.6208 23.1526C47.4683 22.3744 47.0792 21.4639 47.6168 20.7526" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M54.6234 24.4327C55.439 24.2001 56.2693 25.112 55.8495 25.8795C55.566 26.6055 54.5044 26.7151 54.0471 26.1027C53.527 25.5479 53.896 24.5731 54.6234 24.4327" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M44.9256 25.9709C45.7479 25.9696 46.1317 27.1569 45.4631 27.6369C44.9577 28.1129 44.0766 27.784 43.9456 27.1235C43.749 26.5271 44.3253 25.9121 44.9256 25.9709" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M33.6233 43.1433C34.7946 44.2397 34.9002 45.907 34.9764 47.4085C35.0379 49.2029 35.1516 51.0052 35.5447 52.7608C35.9364 51.7901 36.2199 50.7592 36.7935 49.8741C37.319 49.0545 38.4836 48.6721 39.3339 49.239C40.1415 49.5719 40.2685 50.5292 40.211 51.2954C40.0747 52.9012 39.3861 54.388 38.9609 55.9283C38.2415 58.1024 37.9928 60.4195 38.0864 62.7019C38.2803 63.8117 38.406 64.9362 38.1586 66.0499C37.7522 65.011 37.6465 63.8959 37.4005 62.8169C37.2414 62.1457 37.2254 61.4558 37.2922 60.7739C37.4874 58.3364 37.5423 55.8668 38.0851 53.4748C38.2562 52.5723 39.374 52.3423 39.6628 51.5053C39.9303 50.8461 39.9891 49.8607 39.2443 49.489C38.6387 49.2537 38.0089 49.6615 37.6104 50.0934C36.3857 51.504 36.0167 53.3932 35.673 55.1756C34.8106 52.3356 34.1153 49.4449 33.5832 46.5247C33.2289 45.4096 33.6166 44.2718 33.6233 43.1433" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M30.8864 43.5663C31.2501 45.2083 31.7515 46.8141 32.2395 48.4212C32.7877 50.8921 33.5846 53.3122 33.9122 55.8273C32.539 54.6546 31.4801 53.0528 29.794 52.2907C29.0613 51.9284 28.005 52.1891 27.7416 53.0248C27.4341 53.7896 27.866 54.6319 28.5144 55.0638C29.3715 55.628 30.3529 55.9824 31.1939 56.5747C32.9054 57.7098 34.0406 59.4668 35.1276 61.1648C36.1438 62.6891 36.4954 64.5222 36.8444 66.2925C36.0702 65.2496 35.7814 63.9459 35.0113 62.903C34.5406 62.1944 33.9269 61.6047 33.3225 61.0137L33.2717 60.9522C32.2208 59.2863 30.3208 58.5054 28.961 57.1443C27.8125 56.0078 26.5356 54.7121 26.3417 53.0314C26.1839 51.8508 27.2014 50.729 28.3888 50.7718C29.917 50.6996 31.0789 51.8361 32.1941 52.7119C31.6766 50.5819 31.0375 48.4801 30.6431 46.3234C30.4479 45.3968 30.6578 44.4635 30.8864 43.5663" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M28.1771 48.1788C27.8081 48.3553 28.1517 48.8206 28.2292 49.0854C28.4926 48.9864 28.99 48.9931 28.9432 48.584C29.1064 48.1788 28.4552 47.8646 28.1771 48.1788M27.7252 45.8483C27.1462 45.6812 26.4683 46.1826 26.5526 46.8043C26.5619 47.4782 27.3695 47.9609 27.9525 47.5852C28.6972 47.2335 28.5354 45.9967 27.7252 45.8483M37.5419 46.0943C36.8012 46.101 36.261 46.9928 36.6728 47.632C37.0098 48.3179 38.0741 48.4021 38.4846 47.7389C39.0368 47.0624 38.3856 46.0141 37.5419 46.0943M33.8677 37.9877C36.0538 38.2003 37.997 42.8394 40.782 44.0476C45.2532 48.8303 44.615 53.8507 40.5169 59.0452C40.2161 58.3138 40.1773 57.3137 39.4031 56.9072C39.4005 57.7482 40.0302 58.4154 40.1158 59.2404C40.2281 60.1737 39.946 61.0936 39.8684 62.0202C39.6786 63.6795 39.6291 65.5326 38.419 66.8256C38.3308 66.5662 38.2439 66.3095 38.1583 66.0501C38.4057 64.9363 38.2813 63.8105 38.0861 62.7021C37.9925 60.4197 38.2412 58.1026 38.9606 55.9285C39.3858 54.3882 40.0743 52.9014 40.2107 51.2955C40.2682 50.5294 40.1412 49.5721 39.3336 49.2391C38.4832 48.6722 37.3186 49.0546 36.7932 49.8742C36.2209 50.7594 35.9374 51.7903 35.5457 52.761C35.1513 51.0054 35.0376 49.203 34.9761 47.4087C34.8999 45.9071 34.7943 44.2398 33.623 43.1434C33.4264 42.5498 33.3114 41.9173 32.9959 41.3705C32.6496 40.7982 31.7725 40.7207 31.3192 41.2047C30.66 41.8064 30.878 42.7731 30.886 43.5659C30.6574 44.4631 30.4474 45.3964 30.644 46.323C31.0384 48.4797 31.6762 50.5815 32.1937 52.7115C31.0785 51.8357 29.918 50.6992 28.3884 50.7714C27.2024 50.73 26.1849 51.8518 26.3413 53.0311C26.5352 54.7131 27.8134 56.0074 28.9606 57.1439C30.3218 58.505 32.2204 59.2859 33.2727 60.9519C32.5533 60.7125 32.7753 61.7167 32.6937 62.1231C30.406 61.71 28.3549 60.3823 26.8895 58.5973C24.1071 55.2399 23.7531 50.0575 25.724 45.6397C25.3291 43.3839 26.5432 36.1047 29.8658 34.9254C31.0411 34.4361 32.6536 38.2604 33.8677 37.9877" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M33.323 61.0135C33.9273 61.6045 34.5411 62.1941 35.0117 62.9041C35.7819 63.9457 36.0707 65.2493 36.8448 66.2922C36.9277 66.585 37.0106 66.8805 37.0882 67.18C35.4957 66.4741 34.4408 64.9913 33.6064 63.5218C33.1184 62.7771 33.2949 61.8491 33.323 61.0135" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M31.3187 41.2049C31.7733 40.7209 32.6491 40.7984 32.9954 41.3707C33.3109 41.9176 33.4259 42.5487 33.6238 43.1437C33.6171 44.2708 33.2294 45.41 33.5823 46.5251C34.1158 49.4453 34.8098 52.3347 35.6735 55.176C36.0171 53.3936 36.3862 51.5044 37.6109 50.0924C38.0094 49.6619 38.6391 49.2541 39.2448 49.4881C39.9882 49.8611 39.9294 50.8452 39.662 51.5057C39.3732 52.3427 38.2554 52.5727 38.0856 53.4739C37.5414 55.8672 37.4866 58.3368 37.2927 60.7729C37.2259 61.4562 37.2419 62.1461 37.401 62.8173C37.6457 63.8963 37.7527 65.0114 38.1578 66.0503C38.2447 66.3084 38.3303 66.5664 38.4199 66.8258C38.0401 67.1521 37.5842 67.3179 37.0881 67.1802C37.0106 66.8807 36.9277 66.5852 36.8448 66.2923C36.4958 64.5221 36.1428 62.6889 35.128 61.1647C34.041 59.4666 32.9058 57.7097 31.1943 56.5745C30.352 55.9822 29.3719 55.6292 28.5135 55.0636C27.8664 54.6318 27.4345 53.7894 27.742 53.0246C28.0041 52.1889 29.0617 51.9295 29.7931 52.2919C31.4805 53.0527 32.5394 54.6545 33.9113 55.8284C33.5837 53.3121 32.7881 50.892 32.2386 48.4211C31.7519 46.8139 31.2505 45.2081 30.8868 43.5662C30.8775 42.7733 30.6609 41.8066 31.3187 41.2049" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M37.5418 46.0948C38.3855 46.0146 39.0366 47.0615 38.4844 47.7394C38.074 48.4026 37.011 48.3184 36.6727 47.6311C36.2609 46.992 36.8011 46.1015 37.5418 46.0948" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M27.7253 45.8487C28.5342 45.9971 28.6974 47.2339 27.9526 47.5856C27.3683 47.9613 26.5621 47.4786 26.5527 46.8047C26.4685 46.183 27.145 45.6816 27.7253 45.8487" fill="#ffd969" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M95.6247 19.2543C72.0749 37.2579 52.5376 60.2234 33.6502 82.4268C21.3224 96.9286 7.346 113.361 2.20765 133.094L0.492188 132.643C5.7335 112.522 19.8476 95.9245 32.2997 81.2836C51.25 59.0013 70.8434 35.9636 94.5537 17.8503L95.6247 19.2543Z" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M52.3253 75.1343L51.6287 76.7655C47.8889 75.1597 43.7707 74.4136 39.7087 74.6088L39.6205 72.8425C43.9512 72.6273 48.3435 73.4228 52.3253 75.1343" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M38.0984 63.4985C38.2067 63.9959 38.2682 64.4786 38.3203 64.8998C38.549 66.7703 38.7388 68.6409 38.9006 70.5208C39.1105 72.961 39.1881 75.7381 37.5408 77.8052L36.157 76.6967C37.3964 75.1417 37.3109 72.7684 37.1357 70.6666C36.9793 68.8161 36.7854 66.9669 36.5608 65.115C36.4351 64.04 36.2867 63.1054 35.7786 62.4369L37.1932 61.3726C37.6812 62.0224 37.942 62.7792 38.0984 63.4985" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M69.8011 56.4499L69.3933 58.1774C67.2366 57.66 65.0144 57.3056 62.7962 57.1238C61.169 56.9874 59.273 56.9192 57.5563 57.4474L56.9559 57.6479C56.0026 57.9702 54.9169 58.3405 53.7764 58.0237L54.247 56.3122C54.8647 56.4847 55.6055 56.2347 56.385 55.9659L57.0375 55.7533C59.0377 55.1396 61.1436 55.2105 62.942 55.3549C65.2524 55.5461 67.5602 55.9164 69.8011 56.4499" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M60.6649 47.573C60.9604 48.9274 60.9992 50.2698 60.5232 51.5895L58.8545 50.9905C59.4963 49.2029 58.8011 47.1344 57.9654 44.9844C57.456 43.6915 57.0054 42.6485 56.1964 41.9706L57.3396 40.6095C58.5564 41.635 59.1407 43.1352 59.6113 44.3386C60.0378 45.427 60.4323 46.5073 60.6649 47.573" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M87.8046 34.7866L87.5212 36.5355C84.5114 36.0501 81.4001 35.5527 78.4024 36.0769C77.1562 36.2988 75.8994 36.7026 74.7923 37.0636L74.2441 35.3749C75.4087 35.0032 76.7257 34.5767 78.0948 34.328C81.3854 33.7544 84.7949 34.3012 87.8046 34.7866" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M77.5063 28.3911C77.6119 28.8791 77.6854 29.414 77.7202 29.9876C77.8231 31.6028 77.6694 33.2139 77.2642 34.7716L75.5501 34.3317C75.9044 32.9492 76.0408 31.5292 75.9486 30.1052C75.867 28.708 75.5501 27.6811 74.9845 26.9605L76.3858 25.8707C76.9113 26.5513 77.2856 27.3816 77.5063 28.3911" fill="#968c84" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M102.511 5.2628C102.184 5.01539 101.93 5.53556 101.729 5.72635C101.939 5.91558 102.162 6.35943 102.503 6.13016C102.938 6.0903 102.918 5.36561 102.511 5.2628M104.374 3.79314C104.256 3.20039 103.5 2.82879 102.987 3.18875C102.393 3.50725 102.334 4.4459 102.936 4.79128C103.59 5.29168 104.615 4.57962 104.374 3.79314M108.662 12.6252C108.317 11.9712 107.276 11.8999 106.898 12.5596C106.443 13.1754 106.857 14.1594 107.635 14.2182C108.49 14.3987 109.122 13.3394 108.662 12.6252M114.178 5.64005C114.992 7.67758 113.652 10.6045 111.218 12.4689C111.932 18.5184 105.019 22.4705 98.5245 21.2168C99.0349 20.6105 99.9052 20.1172 99.9108 19.2423C99.1626 19.6272 98.8608 20.4929 98.1658 20.9469C97.3889 21.4761 96.4415 21.6463 95.5846 22.0042C94.0221 22.5971 92.6074 23.5022 90.9023 23.0219C91.0934 22.8229 91.2039 22.6684 91.3936 22.4738C92.497 22.1823 93.2631 21.4175 94.1584 20.735C96.1441 19.6029 98.3156 18.7608 100.578 18.4013C102.142 18.0719 103.779 18.0012 105.267 17.3835C105.975 17.0845 106.766 16.5315 106.691 15.6615C106.806 14.6457 105.93 13.7867 104.961 13.6965C103.911 13.5932 102.865 13.8144 101.823 13.9126C103.202 12.7564 104.752 11.829 106.317 10.9488C107.616 10.1939 109.049 9.33307 109.486 7.78926C109.923 7.34222 110.431 6.95039 110.771 6.41818C111.121 5.84819 110.789 5.03383 110.149 4.85193C109.313 4.54416 108.553 5.18131 107.853 5.5531C106.952 5.76094 106.026 6.00268 105.292 6.60121C103.558 7.9437 101.983 9.47363 100.328 10.9121C100.595 9.52051 101.072 7.96726 100.305 6.64193C99.7976 5.56785 98.3341 5.17922 97.3584 5.85957C95.953 6.80442 95.3893 8.53355 94.9072 10.0757C94.322 11.9091 94.5 13.9551 93.5032 15.6522C93.3856 14.9054 92.5965 15.5654 92.1976 15.6781C91.5147 13.4555 91.7519 11.0235 92.6663 8.90293C94.3701 4.88989 99.179 2.46676 101.336 3.00025C104.563 1.52444 111.247-2.93165 113.82-0.522474C114.794 0.297034 113.378 4.68528 114.178 5.64005" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M85.3854 55.7342C85.3889 55.3252 84.8202 55.4308 84.5483 55.3829C84.52 55.6637 84.2963 56.1069 84.6832 56.2458C84.976 56.5687 85.5452 56.1229 85.3854 55.7342M87.6763 56.3531C88.081 55.9067 87.9299 55.0776 87.3344 54.8795C86.7245 54.5924 85.9352 55.1054 86.017 55.7945C86.0061 56.6165 87.1878 57.0149 87.6763 56.3531M83.1406 65.0614C83.4596 64.3943 82.8955 63.5171 82.1411 63.6058C81.3756 63.6076 80.8316 64.5257 81.2471 65.1876C81.6133 65.9809 82.841 65.8551 83.1406 65.0614M92.0364 65.3272C90.885 67.1951 87.2054 67.7825 84.8323 66.9887C84.3564 71.1135 73.0637 68.006 70.1983 62.0413C70.9887 62.0909 71.905 62.4973 72.6102 61.9781C71.8552 61.6081 70.9794 61.8812 70.2015 61.5944C69.3132 61.2854 68.6117 60.6265 67.8137 60.1502C66.4072 59.2493 65.079 58.8403 64.4505 57.1843C64.7231 57.2184 65.0185 57.221 65.2885 57.2567C66.1786 57.9687 66.9018 57.9367 67.9824 58.2495C70.0746 59.1685 72.0466 60.4106 73.6829 62.0128C74.8803 63.0723 75.9129 64.3442 77.2951 65.1721C77.9579 65.5622 78.8727 65.8688 79.5275 65.2888C80.4111 64.7757 80.5789 63.5605 80.0744 62.7293C79.5308 61.8238 78.7292 61.116 78.0304 60.338C79.7806 60.7562 81.4481 61.447 83.0879 62.1805C84.4687 62.7734 86.0136 63.4108 87.5133 62.8402C88.1331 62.9246 88.7511 63.0989 89.3808 63.0575C90.0476 62.9966 90.502 62.2447 90.2672 61.6233C90.0152 60.7668 89.0528 60.5376 88.3366 60.1963C87.6317 59.5966 86.886 58.9969 85.968 58.7669C83.8569 58.1722 81.6878 57.8197 79.548 57.3486C80.8241 56.7332 82.3548 56.1909 82.9633 54.7852C83.5229 53.737 82.9626 52.33 81.8343 51.9519C80.2386 51.3869 78.5144 51.9655 76.988 52.4978C75.1674 53.1207 73.6305 54.4846 71.6725 54.6951C72.2037 54.1553 71.2033 53.9139 70.8756 53.6606C72.252 51.7868 74.3468 50.5288 76.5939 49.9985C80.8326 48.9755 85.4948 51.5042 87.6748 53.9758C91.5449 55.632 95.6438 58.0405 95.2421 61.5431C95.1649 62.8125 92.3258 64.1161 92.0364 65.3272" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M65.3596 73.1821C65.3578 72.7724 64.7901 72.8862 64.5167 72.8427C64.4937 73.1243 64.2763 73.5707 64.6657 73.7026C64.9616 74.024 65.5251 73.568 65.3596 73.1821M67.659 73.7703C68.058 73.3163 67.8944 72.4903 67.2964 72.3006C66.6828 72.0224 65.9013 72.5457 65.9927 73.2339C65.992 74.057 67.1799 74.4387 67.659 73.7703M63.2455 82.54C63.5555 81.8686 62.9805 80.9988 62.2266 81.0989C61.4614 81.1118 60.9306 82.0377 61.3562 82.6914C61.7324 83.4807 62.9574 83.339 63.2455 82.54M72.1451 82.6807C71.0207 84.5648 67.8812 85.2773 64.9222 84.4762C60.5497 88.7174 53.2117 85.6251 50.2635 79.7035C51.0552 79.7409 51.9756 80.1328 52.6741 79.6059C51.914 79.2452 51.0429 79.5312 50.2602 79.2547C49.3679 78.9588 48.6574 78.3095 47.8527 77.846C46.4327 76.9646 44.8545 76.3903 44.2006 74.744C44.4748 74.7749 44.6647 74.769 44.9344 74.8015C45.8361 75.5011 46.9089 75.6454 47.9949 75.9422C50.1002 76.8323 52.088 78.0461 53.7473 79.625C54.9589 80.6678 56.0093 81.9256 57.4036 82.7324C58.0714 83.1138 58.9909 83.4063 59.6364 82.818C60.5133 82.2926 60.6641 81.0753 60.1472 80.2506C59.5917 79.3537 58.7804 78.6565 58.0696 77.8884C59.8257 78.2818 61.504 78.9504 63.1535 79.6591C64.5424 80.233 66.0962 80.8482 67.588 80.2578C68.2087 80.3337 68.8288 80.4997 69.4581 80.4467C70.1233 80.3784 70.5685 79.6197 70.3248 79.0014C70.0612 78.1497 69.0936 77.9331 68.373 77.6025C67.6607 77.0128 66.9064 76.4242 65.9854 76.2048C63.8654 75.642 61.6939 75.3202 59.5459 74.8787C60.8133 74.2455 62.3372 73.6814 62.9245 72.2673C63.4696 71.2118 62.8896 69.813 61.7566 69.451C60.1524 68.9083 58.4363 69.5104 56.9183 70.0639C55.1063 70.7123 53.5889 72.0963 51.6353 72.3349C52.1571 71.788 51.1536 71.5619 50.8217 71.3134C52.1729 69.421 54.2492 68.1326 56.4892 67.5714C60.7123 66.488 65.5577 68.837 66.4435 70.8744C69.576 72.5425 77.1752 75.1466 76.8236 78.6537C76.7639 79.925 72.418 81.4655 72.1451 82.6807" fill="#e8bb9e" fillRule="evenodd" opacity="1" stroke="none"/>
            </g>
            </svg>
         </div>
    )
}

export default TwigBrown;








