import React from 'react';
import './svgStyle.css';

const FlYellow = () => {
    return (
        <div id="flYellow" className='mid svg'>
           <svg 
            height="100%" 
            strokeMiterlimit="10" 
            version="1.1" 
            viewBox="0 0 69.5889 165.606" 
            width="100%" 
            >
                
            <g>
            <path d="M139.518 137.929L141.531 136.902C122.433 104.152 98.826 78.9085 71.367 61.872C59.0364 54.2196 47.536 50.9938 32.9164 51.0832L32.9509 53.2426C47.2668 53.1532 58.0318 56.1748 70.1071 63.6685C97.2511 80.5102 120.604 105.495 139.518 137.929" fill="#dfd387" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M141.633 151.796L142.928 150.025C117.086 133.243 96.5385 116.025 80.1106 97.3849C60.872 75.561 47.7315 52.2189 41.0583 28.0111L38.8338 28.5383C45.5954 53.0596 58.8863 76.6827 78.3444 98.7569C94.9106 117.55 115.614 134.899 141.633 151.796" fill="#dfd387" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M57.9253 39.3532C58.3687 37.3866 58.982 35.3453 60.4926 33.9261C61.2222 33.2427 62.2322 32.7179 63.2527 32.8853C63.7615 32.9682 64.2245 33.2181 64.6462 33.5002C67.4038 35.3497 68.6788 38.6681 68.8046 41.841C68.9866 46.4264 66.9021 50.9636 63.4553 54.1547C62.159 55.3569 59.9544 57.4504 58.1153 55.8908C56.1976 54.2663 56.6043 51.0137 56.685 48.8661C56.8056 45.6681 57.2204 42.4807 57.9253 39.3532" fill="#dfd387" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M40.9714 66.7112C39.4081 66.8944 37.7473 66.9841 36.3231 66.34C35.6375 66.0297 34.9964 65.4946 34.8662 64.7917C34.8004 64.4395 34.8686 64.0768 34.9724 63.7339C35.6509 61.4922 37.783 59.8646 40.0938 59.0224C43.4342 57.8025 47.3034 58.0733 50.5198 59.5525C51.7317 60.1092 53.8264 61.0448 53.1345 62.619C52.4127 64.2589 49.9088 64.7723 48.3037 65.237C45.9134 65.9247 43.4574 66.4201 40.9714 66.7112" fill="#dfd387" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M31.507 50.9515C31.5787 49.7747 29.474 49.9338 29.7082 51.0432C29.7633 52.1002 31.607 51.9943 31.507 50.9515" fill="#ffffff" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M28.0314 51.5135C28.0714 52.2046 26.842 52.3964 26.7312 51.6877C26.4088 50.8503 27.9814 50.6318 28.0314 51.5135M31.1645 54.3562C30.9081 54.8864 29.9766 54.4869 30.2577 53.9595C30.4872 53.3576 31.5055 53.8059 31.1645 54.3562M31.5076 50.9513C31.6075 51.9942 29.7624 52.1 29.7087 51.0431C29.4746 49.9336 31.5793 49.7745 31.5076 50.9513M31.809 47.6654C31.6309 47.9974 30.8033 48.3268 30.7681 47.7104C30.3213 46.8952 32.1467 46.6396 31.809 47.6654M35.616 52.0508C35.2065 50.2043 35.0946 48.2804 34.2211 46.5504C33.5603 44.7674 31.8615 43.4056 31.6654 41.4647C31.4868 40.4671 30.2932 40.6484 29.5894 40.2407L29.4438 40.3016C28.6067 42.3059 29.4142 44.5448 28.4506 46.5298C26.9948 45.2033 25.9734 43.5218 24.4948 42.2093C22.1748 40.0833 19.4374 37.9456 16.0869 37.6577C17.707 38.6363 19.5674 39.2974 20.8887 40.6702C22.8714 42.6606 24.6547 44.8922 25.7764 47.421C22.0748 46.1509 18.7481 43.7123 14.7468 43.3626C17.9287 45.0176 21.2769 46.5261 23.9485 48.9027C19.8084 48.9202 15.6525 48.9575 11.5337 49.4157C10.5265 49.5149 9.55494 49.7938 8.61231 50.1335C13.1681 50.052 17.7548 50.0754 22.2739 50.7455C22.9119 50.8534 23.5302 51.0263 24.144 51.2018C19.9638 52.8373 15.3365 53.557 11.5016 55.9058C15.5427 54.6939 19.6993 53.7583 23.9404 53.424C23.3347 54.0397 22.6842 54.6159 21.9619 55.111C19.4166 56.9172 16.9318 58.8245 14.1489 60.3091C14.0265 60.4539 13.7805 60.7422 13.6568 60.8856C14.1643 60.7232 14.6672 60.5675 15.1597 60.385C17.4397 59.1113 19.8947 58.1529 22.2073 56.9264C23.2372 56.3862 24.2859 55.8773 25.374 55.4518C23.9607 58.3105 22.0629 60.9085 20.4031 63.6352C23.0325 61.7123 24.8467 59.0326 27.1201 56.7804C27.5377 58.4248 28.0486 60.1873 29.5699 61.2555C30.9981 61.8255 32.4831 60.821 33.2648 59.7505C34.9184 57.5388 35.8051 54.7615 35.616 52.0508" fill="#dfd387" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M28.45 46.53C29.4137 44.545 28.6061 42.3062 29.4433 40.3005L29.4287 40.0035C27.9442 37.2716 25.6191 34.8615 22.7253 33.3848C20.9012 32.4364 18.6625 32.1225 16.6702 32.7479C14.9474 33.2723 13.7903 35.0401 14.0912 36.7263C14.2522 38.1266 15.1587 39.3059 16.0945 40.3518C12.6844 40.9149 9.08357 42.228 7.09076 45.0357C5.24864 47.4479 4.42597 50.6103 5.21217 53.5341C6.33585 57.2066 10.0543 60.1529 14.1498 60.308C16.9312 58.8247 19.4175 56.9161 21.9614 55.1098C22.6836 54.6161 23.3342 54.0386 23.9412 53.4242C19.7002 53.7586 15.5422 54.6927 11.501 55.906C15.3359 53.5572 19.9632 52.8375 24.1434 51.202C23.5296 51.0265 22.9129 50.8523 22.2733 50.7457C17.7557 50.0757 13.169 50.0523 8.6118 50.1324C9.55436 49.794 10.5274 49.5152 11.5331 49.4159C15.6519 48.9577 19.8078 48.9204 23.9494 48.9016C21.2763 46.5263 17.9296 45.0166 14.7463 43.3628C18.7475 43.7125 22.0757 46.1512 25.7758 47.4212C24.6556 44.8925 22.8723 42.6609 20.8881 40.6704C19.5682 39.2977 17.7079 38.6366 16.0864 37.6579C19.4368 37.9458 22.1743 40.0822 24.4957 42.2095C25.9729 43.522 26.9942 45.2036 28.45 46.53" fill="#e8e791" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M21.9144 68.453C25.3671 67.1662 28.3754 64.6166 29.5698 61.2555C28.0485 60.1872 27.5376 58.4247 27.12 56.7803C24.8466 59.0326 23.0324 61.7123 20.4031 63.6337C22.0629 60.9084 23.9606 58.3104 25.3739 55.4518C24.2858 55.8772 23.2372 56.3848 22.2087 56.9265C19.8946 58.1529 17.4397 59.1098 15.1596 60.3849L15.6463 60.5231C14.1104 61.7618 12.943 63.664 13.2818 65.6237C13.7357 67.193 14.9498 68.7294 16.7005 69.142C18.4616 69.6612 20.2841 69.0555 21.9144 68.453" fill="#e8e791" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M31.8085 47.6648C32.1462 46.6389 30.3208 46.8946 30.7676 47.7098C30.8042 48.3277 31.6319 47.9968 31.8085 47.6648" fill="#f7f5e6" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M31.1648 54.3568C31.5059 53.8064 30.4875 53.3581 30.258 53.96C29.9769 54.4875 30.9085 54.887 31.1648 54.3568" fill="#faf9f0" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M28.0314 51.5135C27.9814 50.6318 26.4088 50.8503 26.7312 51.6877C26.842 52.3964 28.0714 52.2046 28.0314 51.5135" fill="#f8f7e9" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M41.4461 29.0529C42.6686 29.336 42.8601 27.3626 41.6597 27.3727C40.5412 27.2275 40.3368 28.9537 41.4461 29.0529" fill="#ffffff" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M41.4557 25.7342C40.7258 25.6433 40.7341 24.4713 41.4966 24.502C42.4285 24.3587 42.3879 25.8527 41.4557 25.7342M37.9379 28.1045C37.4295 27.7708 38.0065 26.984 38.5106 27.3408C39.1016 27.6638 38.4576 28.5241 37.9379 28.1045M41.4458 29.0526C40.3365 28.9534 40.5424 27.2273 41.6594 27.3738C42.8613 27.3624 42.6683 29.3358 41.4458 29.0526M44.8353 29.9406C44.5191 29.7153 44.315 28.8885 44.9676 28.9696C45.8987 28.7064 45.8522 30.4426 44.8353 29.9406M39.5874 32.6488C41.5945 32.6112 43.6268 32.8642 45.588 32.3776C47.5689 32.096 49.2866 30.7778 51.3538 30.9555C52.4292 30.9771 52.4446 29.8386 52.9923 29.2637L52.9533 29.1183C50.9992 27.9726 48.5154 28.3039 46.6012 27.0447C48.241 25.9454 50.178 25.3128 51.8059 24.1894C54.4318 22.4386 57.1388 20.3037 58.0181 17.2588C56.7126 18.5748 55.7012 20.1728 54.0379 21.1385C51.6124 22.6041 48.9692 23.8401 46.1278 24.4094C48.0944 21.2228 51.2198 18.5982 52.2723 14.9621C49.9955 17.5986 47.838 20.4138 44.8917 22.4447C45.5838 18.6125 46.2591 14.7642 46.4867 10.8708C46.5562 9.92122 46.4307 8.97061 46.2383 8.03564C45.5392 12.2642 44.7268 16.5004 43.2482 20.5541C43.0263 21.1248 42.7387 21.6629 42.4498 22.1996C41.4545 18.0299 41.4962 13.6179 39.6956 9.63705C40.2711 13.5978 40.5368 17.6174 40.1593 21.599C39.6183 20.9233 39.126 20.2158 38.7304 19.4571C37.2772 16.7682 35.706 14.1183 34.6326 11.2698C34.5009 11.1303 34.239 10.8487 34.1118 10.7081C34.1945 11.2072 34.2716 11.7019 34.3784 12.19C35.3195 14.534 35.9014 16.9823 36.7871 19.3467C37.1769 20.3999 37.5294 21.4635 37.7884 22.5482C35.0375 20.7114 32.6443 18.4754 30.0745 16.4364C31.6353 19.2235 34.1287 21.3974 36.0957 23.9174C34.3034 23.9971 32.3696 24.1435 30.9907 25.3522C30.1469 26.568 30.9447 28.1258 31.9313 29.0477C33.9639 30.9857 36.7187 32.3212 39.5874 32.6488" fill="#dfd387" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M46.6007 27.0448C48.5164 28.304 50.9986 27.9727 52.9528 29.1184L53.2673 29.1604C56.3831 28.2933 59.3071 26.5891 61.3495 24.1892C62.6569 22.6776 63.3712 20.6652 63.0573 18.7082C62.8051 17.0164 61.1521 15.6199 59.3352 15.5867C57.8408 15.4747 56.4502 16.0944 55.1946 16.767C55.1908 13.5098 54.4342 9.93596 51.8381 7.5745C49.628 5.42427 46.4572 4.07709 43.2612 4.26332C39.2212 4.62245 35.4975 7.51324 34.6321 11.2698C35.7069 14.1185 37.2781 16.7684 38.7313 19.4572C39.1255 20.2159 39.6192 20.9235 40.1587 21.5991C40.5378 17.6162 40.272 13.5979 39.6965 9.6372C41.4971 13.6181 41.4539 18.03 42.4493 22.1983C42.7382 21.663 43.0272 21.1249 43.2491 20.5543C44.7263 16.5005 45.5402 12.263 46.2378 8.03571C46.4302 8.97068 46.5557 9.92128 46.4861 10.8709C46.2601 14.7644 45.5832 18.6126 44.8912 22.4448C47.8389 20.414 49.9949 17.5987 52.2717 14.9622C51.2193 18.5983 48.0953 21.2229 46.1274 24.4081C48.9687 23.8401 51.6119 22.6042 54.0388 21.1387C55.7007 20.1728 56.7121 18.5749 58.0176 17.2589C57.1398 20.3025 54.4312 22.4387 51.8054 24.1895C50.1775 25.3129 48.2405 25.9454 46.6007 27.0448" fill="#e8e791" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M24.7694 16.9417C25.5236 20.3707 27.6734 23.624 30.9906 25.3512C32.3694 24.1439 34.3032 23.9975 36.0956 23.9164C34.1286 21.3964 31.6351 19.2238 30.0744 16.4368C32.6441 18.4758 35.0373 20.7118 37.7883 22.5485C37.5294 21.4625 37.1767 20.4003 36.787 19.3471C35.9012 16.9827 35.3208 14.5344 34.3782 12.1903L34.1483 12.6131C33.1164 10.9641 31.3246 9.53244 29.2152 9.48187C27.4934 9.6123 25.6773 10.4499 24.9432 11.9914C24.0967 13.5253 24.4179 15.3215 24.7694 16.9417" fill="#e8e791" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M44.8353 29.9406C45.8521 30.4426 45.8986 28.7078 44.9675 28.9696C44.3149 28.8885 44.519 29.7153 44.8353 29.9406" fill="#f7f5e6" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M37.9385 28.105C38.4568 28.5233 39.1008 27.6643 38.5097 27.3413C38.0071 26.9845 37.4302 27.77 37.9385 28.105" fill="#faf9f0" fillRule="evenodd" opacity="1" stroke="none"/>
            <path d="M41.4557 25.7342C42.3879 25.8527 42.4285 24.3587 41.4966 24.502C40.7341 24.4713 40.7258 25.6433 41.4557 25.7342" fill="#f8f7e9" fillRule="evenodd" opacity="1" stroke="none"/>
            </g>
            </svg>
         </div>
    )
}

export default FlYellow;








