import React from 'react';
import './svgStyle.css';

const Blob = () => {
    return (
        <div id="blob" 
        className='svg'
        >
            <svg
            width="700" 
            height="700" 
            viewBox="0 -95 200 300" 
            className="rotate"> 
            <path d="M91.57862262474659 0.35522892241876036 C60.921866940188174 -4.037189184101166 8.034435974896741 54.81268503550373 1.1309046752384688 85.00326736708236 C-4.401731485992278 109.19863931311446 17.799325277153827 161.79166268583282 36.930864167311775 177.60337689377906 C55.508962539870744 192.95768705568304 108.36193555602048 200.47155232106013 131.79001287256006 194.8124205026033 C147.53817889704854 191.00839739701934 180.15560039002335 171.31811184736745 183.24246976870253 155.41381801325952 C191.82253850055758 111.20723927987201 136.1549422820313 6.742004992246001 91.57862262474659 0.35522892241876036Z" 
            stroke="none" 
            fill="#F0EDEB"  />
            </svg>
         </div>
    )
}

export default Blob;